
export default {
  props: {
    place: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      options: {
        zoom: 16,
        mapId: null,
        scrollwheel: true,
        clickableIcons: false,
        disableDefaultUI: true,
        gestureHandling: 'greedy',
        zoomControl: true,
        scaleControl: true,
        // fullscreenControl: false,
        zoomControlOptions: {
          position: this.dir === 'ltr' ? 8 : 4
        }
      }
    }
  },
  fetch () {
    this.options.mapId = this.colorMode === 'dark' ? this.$config.googleMapDarkId : this.$config.googleMapLightId
  },
  watch: {
    colorMode (value) {
      this.options.mapId = value === 'dark' ? this.$config.googleMapDarkId : this.$config.googleMapLightId
    }
  }
}
