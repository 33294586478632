import { render, staticRenderFns } from "./Hotel.vue?vue&type=template&id=19ef3752&scoped=true&"
import script from "./Hotel.vue?vue&type=script&lang=js&"
export * from "./Hotel.vue?vue&type=script&lang=js&"
import style0 from "./Hotel.vue?vue&type=style&index=0&id=19ef3752&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19ef3752",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Stars: require('/vercel/path0/components/partials/Stars.vue').default,Button: require('/vercel/path0/components/common/Button.vue').default,HotelGallery: require('/vercel/path0/components/partials/HotelGallery.vue').default,MapboxMapMarker: require('/vercel/path0/components/partials/MapboxMapMarker.vue').default,GoogleMapMarker: require('/vercel/path0/components/partials/GoogleMapMarker.vue').default,ModalScreen: require('/vercel/path0/components/common/ModalScreen.vue').default,Card: require('/vercel/path0/components/common/Card.vue').default})
