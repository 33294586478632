
import Times from '@/assets/icons/fontawesome/light/times.svg?inline'

export default {
  components: {
    Times
  },

  props: {
    images: {
      type: Array,
      required: true
    },
    nav: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      modalSilde: 3,
      modalOpen: false,
      gallerySettings: {
        fade: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        lazyLoad: 'ondemand',
        asNavFor: '.slider-nav',
        variableWidth: false,
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              // dots: true,
            }
          }
        ]
      },
      navSettings: {
        slidesToShow: 5,
        centerMode: true,
        lazyLoad: 'ondemand',
        asNavFor: '.slider-gallery',
        variableWidth: true,
        focusOnSelect: true,
        swipeToSlide: true,
        arrows: false
      }
    }
  },

  methods: {
    showModal (index) {
      this.modalSilde = index
      this.modalOpen = true
    }
  }
}
