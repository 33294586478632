
export default {
  props: {
    value: {
      type: Number,
      default: null
    },
    full: {
      type: Boolean,
      default: false
    }
  }
}
