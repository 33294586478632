
import Times from '@/assets/icons/fontawesome/light/times.svg?inline'
import ChevronLeft from '@/assets/icons/fontawesome/light/chevron-left.svg?inline'
import ChevronRight from '@/assets/icons/fontawesome/light/chevron-right.svg?inline'
import MapMarkedAlt from '@/assets/icons/fontawesome/regular/map-marked-alt.svg?inline'

export default {
  components: {
    Times,
    ChevronLeft,
    ChevronRight,
    MapMarkedAlt
  },
  props: {
    hotel: {
      type: Object,
      required: true
    },
    gallery: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      modalMap: false
    }
  }
}
